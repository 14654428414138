import React, { useEffect } from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import { Spacing, Box, Grid, Cell } from "components/Layout";
import { Text } from "components/Typography";

import Article from "features/articles/components/Article";
import { useHomepageArticles } from "features/articles/queries";

export default function FeaturedBlogs({ singleColumn = false }) {
  const { data } = useHomepageArticles();


  const articles = data ? data.slice(8,12) : [null, null, null, null];

  return (
    <div>
      <Spacing top={1}>
        <Grid as="ul">
          {articles.map((article, idx) => {
            return (
              <Cell
                as="li"
                lgCol={6}
                spacing={1.5}
                key={article ? article.id : `placeholder-${idx}`}
              >
                <Article article={article} />
              </Cell>
            );
          })}
        </Grid>
      </Spacing>
    </div>
  );
}

const Anchor = styled(RouterLink)`
  display: inline-block;
  text-decoration: none !important;
  position: relative;
  overflow: visible;
  &:hover {
    ${Text}, span {
      background-color: #e0e7ec;
    }
  }
`;
